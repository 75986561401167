import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { UserContext } from '../context/UserContext';
import { hasAccessRights } from '../helpers/helpers';
import NoPermissionsPage from '../screens/NoPermissionsPage';
import ComingSoonPage from '../screens/ComingSoonPage';

const ReportingAccessVerification = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation();
  const { userAccessRights } = useContext(UserContext);

  const subLoginAcceptablePaths =  ['/tronc']; // Add your acceptable paths here
  const location = useLocation();

  const renderContent = () => {
    if (!hasAccessRights(userAccessRights, 'Reporting')) {
      return (
        <NoPermissionsPage
          title={t('errors.accessRestricted')}
          description={t('errors.sorryYouNeedPermission')}
          actions={
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => {
                window.location.href = process.env.REACT_APP_LINK_BACK_OFFICE || '';
              }}
            >
              {t('actions.goToBackOffice')}
            </Button>
          }
        />
      );
    }

    // Check acceptable paths and access rights
    if (subLoginAcceptablePaths.includes(location.pathname)) {
      return children; // Render children if path is acceptable
    }

    if (hasAccessRights(userAccessRights, 'LocationAreaID', null)) {
      return children; // Render children if access to LocationAreaID is granted
    }

    // Fallback to ComingSoonPage
    return <ComingSoonPage />;
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default ReportingAccessVerification;
