import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Fade, Paper, Popper, Table, TableBody, TableContainer, useTheme } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { DataGridPro, GridColDef, GridPaginationModel, GridPinnedRowsProp, GridSortDirection, GridSortModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { EnhancedTableToolbar } from '../../../components/EnhancedTableToolbar';
import IconMenu, { IMenuItem } from '../../../components/IconMenu';
import ToolButton from '../../../components/ToolButton';
import { SettingsContext } from '../../../context/SettingsContext';
import { TroncFiltersContext } from '../../../context/TroncFiltersContext';
import TroncReportFiltersPanel from './TroncReportFiltersPanel';
import { UIContext } from '../../../context/UIContext';
import { defer, formatValue } from '../../../helpers/helpers';
import { ExportDialog } from './ExportDialog';
import FailedToLoadTronc from './FailedToLoadTronc';
import Loading from '../../transactions/components/Loading';
import NoTronc from './NoTronc';
import { troncHash, TroncRow, TroncSummary } from '../../../models/tronc';
import { UserContext } from '../../../context/UserContext';
import { setupArrayAtPolyfill } from '../../../polyfills';

const FileDownloadIcon =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(89,89,89)' d='M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z' /%3E%3C/svg%3E";
const FileDownloadIconWhite =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(178,178,178)' d='M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z' /%3E%3C/svg%3E";

const DEFAULT_ROWS_PER_PAGE = 10;

interface TroncTableProps {
  rows: TroncRow[];
  summary: TroncSummary;
  currency: string;
  isLoading: boolean;
  isError: boolean;
  page: number;
  setPage: (p: number) => void;
  refresh: () => void;
}

const getSummaryRow = (
  summary: TroncSummary,
  rowLabel: string
) : GridValidRowModel[] => 
  [{
    id: 'summary',
    staffName: rowLabel,
    hours: summary?.totalHours,
    gratuity: summary?.totalGratuity,
    serviceCharge: summary?.totalServiceCharge,
    totalTronc: summary?.totalTronc
  }];

const TroncTable = (props: TroncTableProps) => {
  const { rows, summary, currency, isLoading, isError, refresh, page, setPage } = props;
  const { locale } = useContext(UserContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const filtersButtonRef = useRef(null);
  const [exportMenuAnchor, setExportMenuAnchor] = useState<HTMLElement | undefined>(undefined);
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
  const [exportReportStarted, setExportReportStarted] = useState(false);
  
  const { settings } = useContext(SettingsContext);
  const dense = settings.densePadding === 'true';
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [order, setOrder] = useState('asc' as GridSortDirection);
  const [orderBy, setOrderBy] = useState('date');
  const { filtersOpen, openFilters, closeFilters, chips } = useContext(TroncFiltersContext);
  const [summaryRow, setSummaryRow] = useState<GridPinnedRowsProp>({});

  const { colorMode } = useContext(UIContext);
  
  // Support old browsers
  useEffect(setupArrayAtPolyfill, []);

  const toggleFilters = useCallback(() =>
    defer(() => {
        if (filtersOpen) {
            closeFilters(true);
        } else {
            openFilters();
        }
    }), [filtersOpen, closeFilters, openFilters]
  );

  useEffect(() => {
    setSummaryRow({bottom: getSummaryRow(summary, t('screens.transactions.fields.total'))});
  }, [summary]);

  const defaultColumns: GridColDef[] = [
    {
      field: 'staffName',
      headerName: t('screens.troncReport.fields.staffName'),
      minWidth: 200,
      display: 'flex',
      flex: 1,
    },
    {
      field: 'hours',
      headerName: t('screens.troncReport.fields.hours'),
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      display: 'flex',
      flex: 1,
      valueGetter: (value, row: TroncRow) => (row.hours ?? 0).toFixed(2)
    },
    {
      field: 'gratuity',
      headerName: t('screens.troncReport.fields.gratuity'),
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      display: 'flex',
      flex: 1,
      valueGetter: (value, row: TroncRow) => 
        formatValue(row.gratuity, true, currency, locale)
    },
    {
      field: 'serviceCharge',
      headerName: t('screens.troncReport.fields.serviceCharge'),
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      display: 'flex',
      flex: 1,
      valueGetter: (value, row: TroncRow) => 
        formatValue(row.serviceCharge, true, currency, locale)
    },
    {
      field: 'total',
      headerName: t('screens.troncReport.fields.totalAmount'),
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      display: 'flex',
      flex: 1,
      valueGetter: (value, row: TroncRow) => 
        formatValue(row.totalTronc, true, currency, locale)
    }
  ]

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
      if (sortModel?.length) {
          setOrder(sortModel[0].sort);
          setOrderBy(sortModel[0].field);
      }
    }, [order, setOrder, orderBy, setOrderBy]
  );

  const handlePaginationModelChange = useCallback((paginationModel: GridPaginationModel) => {
      setPage(paginationModel.page);
      setRowsPerPage(paginationModel.pageSize);
    }, [page, setPage, rowsPerPage, setRowsPerPage]
  );

  const toolbarChips = useMemo(() =>
    chips.map((chip) => ({
      ...chip,
      onClick: chip.onClick ? chip.onClick : toggleFilters,
    })), [chips, toggleFilters]
  );

  const openExportMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      setExportMenuAnchor(event.currentTarget);
      setIsExportMenuOpen((prevOpen) => !prevOpen);
    }, [setExportMenuAnchor, setIsExportMenuOpen]
  );
    
  const exportOnComplete = useCallback(() => {
      setExportReportStarted(false);
    }, [setExportReportStarted]
  );

  const exportFilteredTronc = useCallback(() => {
      if (exportReportStarted) {
        return;
      }
      setExportReportStarted(true);
    }, [exportReportStarted, setExportReportStarted]
  );

  const exportMenuItems = useMemo(() =>
    [{
      dataQaId: "exportMenuTroncCSV",
      title: t('export.troncReport'),
      onClick: () => { exportFilteredTronc() },
      icon: (
        <img
          src={colorMode === 'light' ? FileDownloadIcon : FileDownloadIconWhite}
          style={{ width: '22px', height: '22px' }}
          alt={t('export.troncReport')}
        />),
      iconPosition: 'right',
      rightText: '.csv',
    }] as IMenuItem[],
    [t, exportFilteredTronc, colorMode]
  );

  const getExportRows = () => (
    [...rows,
      {requestStartTime: '',
      requestEndTime: '',
      staffName: '',
      staffId: '',
      locationId: '',
      locationName: 'Total',
      hours: summary.totalHours,
      gratuity: summary.totalGratuity,
      serviceCharge: summary.totalServiceCharge,
      totalTronc: summary.totalTronc,
      noOfShiftRecords: 0
    }]
  );

  return (
    <Box id="box-container"
      sx={{ width: '100%', flexGrow: 1 }}>
      <Paper 
        sx={{ 
          width: '100%', 
          height: '100%',
          display: 'flex',
          flexFlow: 'column'
        }} 
        variant="outlined">
        <React.Fragment key="troncTable">
            <EnhancedTableToolbar
                onFilterClick={toggleFilters}
                chips={toolbarChips}
                isFilterOpen={filtersOpen}
                filtersButtonRef={filtersButtonRef}
                extraButtons={
                (!isError && !isLoading && rows.length > 0) ? (
                  <Box sx={{ marginRight: { xs: 0, md: '1.5em' } }}>
                    <ToolButton
                        iconElement={<FileDownloadOutlinedIcon />}
                        tooltip={t('actions.export')}
                        onClick={(ev) => openExportMenu(ev)}
                        text={t('actions.export')}
                        active={isExportMenuOpen}
                        id="exportButton"
                        buttonStyle={{ marginRight: '16px' }}
                        dataQaId="tableExportButton"
                    />
                  </Box>
                ) : undefined
                }
                tableHasTransactions={!isError && !isLoading && rows.length > 0}
            />
            <ExportDialog
              start={exportReportStarted}
              onComplete={exportOnComplete}
              rows={getExportRows()}
            />
            <IconMenu
              items={exportMenuItems}
              open={isExportMenuOpen}
              onClose={() => { setIsExportMenuOpen(false) }}
              ariaLabelledBy="exportButton"
              anchorEl={exportMenuAnchor}
              menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                sx: { marginTop: '16px' },
              }}
            />
            <TableContainer sx={{ flexGrow: 1 }}>
              <Table
                aria-labelledby="troncTitle"
                size={dense ? 'small' : 'medium'}
                sx={{
                    minWidth: 750, 
                    overflow: 'hidden',
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'column',
                }}
            >
              {isLoading && <Loading dense={dense} />}
              {!isLoading && isError && (
                <FailedToLoadTronc dense={dense} onClickRetry={() => defer(refresh)} />
              )}
              {rows.length === 0 && !isLoading && !isError && <NoTronc dense={dense} />}
              {!isLoading && !isError && rows.length > 0 && (
                <TableBody sx={{ flex: 1, position: 'relative' }}>
                  <Box sx={{ 
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    inset: 0
                    }} >
                      <DataGridPro
                        autoHeight={false}
                        columns={defaultColumns}
                        columnHeaderHeight={55}
                        disableColumnMenu
                        getRowHeight={() => 'auto'}
                        rows={rows}
                        getRowId={(row: TroncRow) => troncHash(row)}
                        loading={isLoading}
                        disableMultipleRowSelection
                        onSortModelChange={handleSortModelChange}
                        pagination
                        pageSizeOptions={[10,25,50,100]}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={rows?.length || 0}
                        initialState={{
                            pagination: { paginationModel: { page, pageSize: rowsPerPage } },
                            sorting: { sortModel: [{ field: orderBy, sort: order}] } ,
                        }}
                        localeText={{
                            MuiTablePagination: {
                                labelRowsPerPage: t('components.filters.rowsPerPage')
                            },
                            footerRowSelected: () => ''
                        }}
                        pinnedRows={summaryRow as GridPinnedRowsProp<TroncRow>}
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 600,
                            },
                            '& .MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller, .MuiDataGrid-filler': {
                                backgroundColor: 'background.paper'
                            },
                            '& ::-webkit-scrollbar': {
                                width: '6px',
                                height: '6px'
                            },
                            '& ::-webkit-scrollbar-thumb': {
                                borderRadius: '10px',
                                backgroundColor: theme?.palette?.grey?.['50p']
                            },
                            '& .MuiDataGrid-cell': {
                                minHeight: dense ? '26px': '52px',
                                paddingTop: dense ? '8px' : '16px',
                                paddingBottom: dense ? '8px' : '16px'
                            },
                            '& .MuiDataGrid-container--top [role=row], .MuiDataGrid-container--bottom [role=row]': {
                              fontWeight: 600,
                              backgroundColor: 'background.paper'
                            }
                        }}
                    />
                  </Box>
                </TableBody>
              )}
            </Table>
            </TableContainer>
            <Popper open={filtersOpen} anchorEl={filtersButtonRef.current} placement="bottom-end">
                <Fade mountOnEnter unmountOnExit in={filtersOpen}>
                    <Paper
                        sx={{
                            width: '450px',
                            height: 'calc(100vh - 371px)',
                            marginTop: { xs: '5px', sm: '9px', md: '7px' },
                            position: 'relative',
                            left: '16px',
                            overflow: 'hidden',
                            borderRadius: '0px',
                            boxShadow:
                            '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
                            clipPath: 'inset(0px 0px 0px -74px)',
                            backgroundColor: 'background.paper',
                            backgroundImage: 'none',
                        }}
                    >
                        <TroncReportFiltersPanel />
                    </Paper>
                </Fade>
            </Popper>
          </React.Fragment>
      </Paper>
    </Box>
  )
}


export default TroncTable;
