import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import mediaQuery from 'css-mediaquery';
import { AccessRights, LabsFeature, UserAccessRights, UserRole } from '../types';

export const EN_GB = 'en-GB';
export const GBP = 'GBP';
export interface AssociativeArray {
  [key: string]: any;
}

export const slugify = (string: string) =>
  string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const deepClone = (obj: unknown) => JSON.parse(JSON.stringify(obj));

export const initials = (name: string) =>
  name
    .match(/(^\S?|\s\S)?/g)
    ?.map((v) => v.trim())
    .join('')
    .match(/(^\S|\S$)?/g)
    ?.join('')
    .toLocaleUpperCase();

export const createMatchMedia = (width: number) => (query: string) => ({
  matches: mediaQuery.match(query, {
    width,
  }),
  addEventListener: jest.fn(),
  removeEventListener: jest.fn(),

  media: query,
  onchange: null,
  addListener: jest.fn(),
  removeListener: jest.fn(),
  dispatchEvent: jest.fn(),
});

export function formatValue(
  inputNumber: number,
  isCurrency: boolean,
  currency?: string,
  locale = EN_GB,
  maximumFractionDigits: undefined | number = undefined
) {
  if (inputNumber === undefined || inputNumber === null || Number.isNaN(inputNumber)) {
    return '';
  }
  if (isCurrency) {
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency && currency !== 'TBD' ? currency : GBP,
      maximumFractionDigits
    }).format(inputNumber);
  }
  return Intl.NumberFormat(locale, {maximumFractionDigits}).format(inputNumber);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function defer(fn: (...a: unknown[]) => void, ...args: unknown[]): void {
  setTimeout(() => fn(...args), 0);
}

export const checkChildLinkActive = (locationPath: string, paths: string[] = []) =>
  paths.find((path) => locationPath.includes(path)) != null;

export const checkCurrentLink = (locationPath: string, path: string, excludePath?: string) => {
  let isCurrent;
  if (excludePath && path.includes(excludePath)) {
    isCurrent = false;
  } else {
    isCurrent = path === '/' ? locationPath === path : locationPath.includes(path);
  }

  return isCurrent;
};

export const getBaseDomain = (domain: string) => {
  const split = new URL(domain).hostname.split('.');
  return split.length <= 2 ? split.join('.') : split.slice(1).join('.');
};

export const hasModule = (modules: UserRole[] | undefined, module: string): boolean => {
  if (!modules) {
    return false;
  }

  return modules.filter((el) => el.RoleName === module).length > 0;
};

export const hasFeature = (labsFeatures: LabsFeature[] | undefined, feature: string): boolean => {
  if (!labsFeatures) {
    return false;
  }

  return labsFeatures?.filter((labsFeature) => labsFeature.FeatureName === feature).length > 0;
};

export const hasAtLeastOneModule = (
  modules: UserRole[] | undefined,
  targetModules: string[]
): boolean => {
  if (!modules) {
    return false;
  }

  return modules.filter((el) => targetModules.includes(el.RoleName)).length > 0;
};

export const hasAccessRights = (
  userAccessRights: UserAccessRights | undefined,
  accessRight: AccessRights,
  locationArea?: number | null
): boolean => {
  if (!userAccessRights) {
    return false;
  }

  if (accessRight === 'LocationAreaID') {
    return (
      userAccessRights.LocationAreaID === null || locationArea === userAccessRights.LocationAreaID
    );
  }

  return userAccessRights[accessRight] || false;
};

const referralProgIds: Record<string, string> = {
  GB: '05b01e66-3c76-45c8-9426-99d51bf440e2',
  US: '967d2142-9fd6-475e-af17-7667bbc93378',
  AU: 'bb61a612-058c-4563-bd30-d3d60a0caf25',
  CA: 'a1842326-eb86-45bd-8d07-7343868e82ab',
  MX: '685ca9d9-d6a1-4fc3-8dbd-d34f22b4f2f5',
  ES: '24266c28-be04-4dc7-a882-ac0750f3805e',
  NZ: '144c5475-9902-4753-a9e2-5c3e2e1d9162',
};

export const getReferralLink = (
  companyId: string,
  companyEmail: string,
  companyName: string,
  userTerritory: string
): string => {
  if (!userTerritory || !Object.keys(referralProgIds).includes(userTerritory)) return '';
  return `https://eposnow.referralrock.com/access/?programidentifier=${referralProgIds[userTerritory]}&externalid=${companyId}&lastname=${companyName}&email=${companyEmail}`;
};

export const isSubLogin = (userProfileSub: string, userProfileParentGuid: string) =>
  userProfileSub?.toLowerCase() !== userProfileParentGuid?.toLowerCase();

/**
 * Checks if the column visibility model has at least 1 visible column.
 * @param columns - Grid Column Visibility Model .
 */
export const hasVisibleColumns = ((columns: GridColumnVisibilityModel): boolean => Object.values(columns).some(value => value === true));
